<template>
  <b-overlay
    :show="show"
    rounded="sm"
    no-fade
  >
    <b-container class="bv-example-row">
      <b-row>
        <b-col cols="12">
          <b-card
            no-body
            class="card-browser-states mt-1 text-center"
          >
            <b-card-header>
              <div>
                <b-card-title> {{ name }} </b-card-title>
              </div>
              <button-download v-if="!show" />
            </b-card-header>
            <b-card-body>
              <div>
                <pdf
                  v-for="i in numPages"
                  :key="i"
                  :src="src"
                  :page="i"
                  style="display: inline-block; width: 100%"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { mapActions } from 'vuex'

import pdf from 'vue-pdf'
import ButtonDownload from './ButtonDownload.vue'

export default {
  components: {
    pdf,
    ButtonDownload,
  },

  errorCaptured() {
    return false
  },

  data() {
    return {
      name: 'N/A',
      src: null,
      numPages: undefined,
      show: false,
    }
  },

  mounted() {
    // falta agregar validación "si el usuario que realizo la peticion es el mismo que esta autenticado"

    if (
      this.$route.query.id == null
        || this.$route.query.id === ''
        || this.$route.query.view == null
        || this.$route.query.view === ''
        || this.$route.query.view_id == null
        || this.$route.query.view_id === ''
    ) {
      this.$router.replace({ name: 'not-authorized' })
    }

    this.fetchDynamicData()
  },

  methods: {
    ...mapActions([
      'showAssemblyByClubId',
      'showPowerByClubId',
    ]),

    async fetchDynamicData() {
      this.show = true

      if (this.$route.query.view == 'view_a') {
        await this.fetchAssemblyData()
      } else if (this.$route.query.view == 'view_p') {
        await this.fetchPOAData()
      } else {
        this.$router.replace({ name: 'not-authorized' })
      }
    },

    async fetchAssemblyData() {
      this.name = this.$t('generic.document')

      const assemblyObj = {
        clubId: parseInt(this.$route.query.id, 10),
        assemblyId: parseInt(this.$route.query.view_id, 10),
      }

      // this.showAssemblyByClubId(assemblyObj).then(response => {
      //   this.name = this.$t('generic.document')
      //   this.loadingDocument(response.data.file)
      // }).catch(error => {
      //   console.error(error)
      // }).finally(
      //   this.show = false,
      // )

      /* fake file */
      this.loadingDocument('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf')
    },

    async fetchPOAData() {
      this.name = this.$t('generic.document')

      const powerObj = {
        clubId: parseInt(this.$route.query.id, 10),
        powerId: parseInt(this.$route.query.view_id, 10),
      }

      // this.showPowerByClubId(powerObj).then(response => {
      //   this.name = this.$t('generic.document')
      //   this.loadingDocument(response.data.file)
      // }).catch(error => {
      //   console.error(error)
      // }).finally(
      //   this.show = false,
      // )

      /* fake file */
      this.loadingDocument('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf')
    },

    loadingDocument(dataDocument) {
      this.src = pdf.createLoadingTask(dataDocument)
      this.$nextTick(() => {
        // eslint-disable-next-line no-shadow
        this.src.promise.then(pdf => {
          this.numPages = pdf.numPages
          this.show = false
        }).catch(error => { })
      })
    },
  },
}
</script>
